import { Component, Input, OnInit } from '@angular/core';
import { IsLoggedIn } from '../../../../decorators/is-logged-in.decorator';
import { RestaurantSimple } from '../../../../domains/restaurant-simple';
import { NavigatorService } from '../../../../services/navigator.service';

@Component({
  selector: 'app-navbar-bottom-icon-explore',
  templateUrl: './navbar-bottom-icon-explore.component.html',
  styles: [':host { display: contents; }']
})
export class NavbarBottomIconExploreComponent implements OnInit {
  @Input() restaurant: RestaurantSimple;
  @Input() isRestaurant = false;

  @IsLoggedIn() isLoggedIn: boolean;

  constructor(private navigatorService: NavigatorService) {
  }

  ngOnInit(): void {
  }

  goToExplore() {
    if (this.restaurant && !this.restaurant.isExample) {
      this.navigatorService
        .goToUrl(this.restaurant.explorePath, {restaurantId: this.restaurant.id})
        .then();
    } else {
      this.navigatorService.goToUrl('/').then();
    }
  }
}

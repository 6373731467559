import { Type } from 'class-transformer';
import { BaseLocation } from '../models/base-location';
import { District } from './district';

export class CityWithChild extends BaseLocation {
  @Type(() => District)
  districts: District[];

  districtsLoaded: boolean;

  bigCity: boolean;

  get hasDistricts() {
    return this.districts?.length > 0;
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IsDevice } from '../../../../decorators/is-device.decorator';
import { DeviceType } from '../../../../models/enums/device-type';
import { Place } from '../../../../models/place';

@Component({
  selector: 'app-search-city-modal',
  templateUrl: './search-city-modal.component.html',
  styleUrls: ['./search-city-modal.component.scss']
})
export class SearchCityModalComponent implements OnInit {
  @Output() selectPlace = new EventEmitter<Place>();
  @Output() findMe = new EventEmitter<boolean>();

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  hiddenPlaceholder = false;
  searchValueExists = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  searchInputValue(searchValue: string) {
    this.searchValueExists = searchValue !== '';
  }

  onSelectPlace(place: Place) {
    this.selectPlace.emit(place);
  }

  onFindMe() {
    this.findMe.emit(true);
  }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RestaurantExplore } from '../../../../domains/restaurant-explore';
import { SearchModalState } from '../../../../models/enums/search-modal-state';
import { Picker } from '../../../../models/picker';
import { SearchParams } from '../../../../models/search-params';
import { SearchResult } from '../../../../models/search-result';
import { SegmentedButton } from '../../../../models/segmented-button';
import { SearchModalStateWrapper } from '../../../../models/wrappers/search-modal-state.wrapper';
import { NotificationService } from '../../../../services/notification.service';
import { ShareService } from '../../../../services/share.service';

@Component({
  selector: 'app-search-modal-footer',
  templateUrl: './search-modal-footer.component.html',
  styleUrls: ['./search-modal-footer.component.scss']
})
export class SearchModalFooterComponent implements OnInit {
  @Input() searchParams: SearchParams;
  @Input() searchResult: SearchResult;
  @Input() searchModalState: SearchModalState;

  @Output() restaurantClicked = new EventEmitter<RestaurantExplore>();
  @Output() onStatusChange = new EventEmitter<SearchModalState>();
  @Output() openSearchModal = new EventEmitter<boolean>();

  @ViewChild('searchCityModalRef') searchCityModalRef: ElementRef;
  @ViewChild('videoModalRef') videoModalRef: ElementRef;

  restaurantsPicker: Picker;
  searchModalStateWrapper: SearchModalStateWrapper;
  activeSegmentedButton = 1;

  segments = [
    new SegmentedButton('Targets', SearchModalState.TARGETS),
    new SegmentedButton('Favorites', SearchModalState.FAVORITE),
    new SegmentedButton('Videos', SearchModalState.VIDEO)
  ];

  constructor(
    private shareService: ShareService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    this.searchModalStateWrapper = new SearchModalStateWrapper(this.searchModalState);

    this.segments.forEach((segment, index) => {
      if (segment.value === this.searchModalState) {
        this.activeSegmentedButton = index;
      }
    });

    this.restaurantsPicker = this.searchResult.restaurantsPicker;
  }

  onClickShare() {
    this.shareService.shareUrl(location.href);
  }

  openCitySearchModal() {
    this.openSearchModal.emit(true);
  }

  onRestaurantsPickerSelected(hostname: string) {
    const restaurant =
      this.searchResult.restaurants.find(restaurantI => restaurantI.hostname === hostname);
    this.restaurantClicked.emit(restaurant);
  }

  onFilterClick() {
    this.notificationService.onFilterClicked();
  }

  statusChange(segmentedButton: SegmentedButton) {
    this.searchModalStateWrapper.value = segmentedButton.value;

    this.onStatusChange.emit(this.searchModalStateWrapper.value);
  }
}

<nav class="navbar navbar-top navbar-top-px-0 bg-white fixed-top max-width safe-area-top h-44pxn d-flex align-items-center justify-content-between">
  <div class="navbar-top-left navbar-width-30px"></div>

  <div class="custom-select-navbar-title ">
    {{ (tourType === TourType.DINING ? 'Dining' : 'Walking') + ' tour' }}
  </div>

  <div class="navbar-top-left navbar-width-30px text-white d-flex align-items-center justify-content-end">
    <label class="switcher switcher-secondary mb-5px">
      <input
        [value]="tourType === TourType.DINING"
        type="checkbox"
        class="switcher-input"
        (change)="onSwitcherChange()"
      />
      <span class="switcher-indicator"></span>
    </label>
  </div>
</nav>

<div class="search-modal d-flex flex-column bg-dark">
  <div class="search-modal-content card border-radius-none mt-0 d-flex flex-column h-100 bg-dark">
    <div *ngIf="!hasEmbeds" [appBackgroundUrl]="backgroundImage ? backgroundImage: ''"
      class="position-absolute-0"
    ></div>

    <div *ngIf="!hasEmbeds" class="position-absolute-0 backdrop-filter-brightness-0-85"></div>

    <div #videoList *ngIf="hasEmbeds" class="video-list has-navbar-top">
      <div *ngFor="let embed of locationEmbeds;" class="mb-3">
        <app-embed-video
          *ngIf="embed.tourType == tourType"
          [embed]="embed"
          (deleteEmbed)="onDeleteEmbed(embed)"
        ></app-embed-video>
      </div>
    </div>

    <div class="m-auto"></div>

    <div
      [ngClass]="{'bg-dark-on-modal': hasEmbeds}"
      class="d-flex flex-column align-items-center text-white z-index-1"
      (click)="openVideoModal()"
    >
      <div class="d-flex justify-content-center p-1-3rem">
        Link video
      </div>
    </div>
  </div>
</div>

<ng-template #videoModalRef let-c="close">
  <app-video-modal
    [type]="tourType"
    [searchParams]="searchParams"
    (videoAdded)="onVideoAdded($event)"
  ></app-video-modal>
</ng-template>


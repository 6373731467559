import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { IsDevice } from '../../../../../decorators/is-device.decorator';
import { DeviceType } from '../../../../../models/enums/device-type';
import { Place } from '../../../../../models/place';
import { SearchParams } from '../../../../../models/search-params';
import { GoogleMapsService } from '../../../../../services/google-maps.service';

@Component({
  selector: 'app-search-city-input',
  templateUrl: './search-city-input.component.html',
  styleUrls: ['./search-city-input.component.scss']
})
export class SearchCityInputComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @Input() inputClass: string;
  @Input() searchParams: SearchParams;

  @Output() placeholderHidden = new EventEmitter<boolean>();
  @Output() inputValue = new EventEmitter<string>();
  @Output() selectPlace = new EventEmitter<Place>();
  @Output() onFindMeClick = new EventEmitter<boolean>();

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  @ViewChild('input', { static: true }) input: ElementRef;

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  focus$ = new Subject<string>();

  defaultItems: Array<Place> = [];

  constructor(
    public googleMapsService: GoogleMapsService,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit(): void {
    this.placeholderHidden.emit(false);

    if(this.isDesktop) {
      const findMe = new Place();
      findMe.placeId = 'findMe';
      findMe.description = 'Find me';
      findMe.disabled = true;
      findMe.icon = 'icon-v2 find-me scale-07';
      this.defaultItems.push(findMe);
    }
  }

  ngAfterViewInit() {
    this.adjustDropdownWidth();
  }

  ngAfterViewChecked() {
    this.adjustDropdownWidth();
  }

  adjustDropdownWidth() {
    if(this.isDesktop) {
      const inputElement = document.getElementById('search-input');
      const dropdownElement = document.querySelector('ngb-typeahead-window');

      if (dropdownElement) {
        this.renderer.setStyle(dropdownElement, 'width', `${inputElement.offsetWidth}px`);
      }
    }
  }

  onFocus() {
    this.focus$.next('');
  }

  onHover() {
    this.focus$.next('');
  }

  searchCity: any = (text$: Observable<string>) => {
    return merge(text$, this.focus$).pipe(
      switchMap(term => {
        if (term === '') {
          return of(this.defaultItems);
        } else {
          return this.googleMapsService.getPredictions(term, 'name').pipe(
            map(places => places.filter(place => !place.disabled)),
            catchError(() => of([]))
          );
        }
      })
    );
  }

  searchCityMobile: any = (text: Observable<string>) => {
    return text.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        this.googleMapsService.getPredictions(term, 'name').pipe(
          catchError(() => {
            return of([]);
          })
        )
      ),
      tap()
    );
  }

  onInput(target: any) {
    const value = target.value;
    this.placeholderHidden.emit(value !== '');
    this.inputValue.emit(value);
  }

  selectItem(placeEvent: NgbTypeaheadSelectItemEvent<Place>) {
    if (placeEvent.item.disabled) {
      placeEvent.preventDefault();
    }

    if (placeEvent.item.placeId === 'findMe' || placeEvent.item.placeId === 'location') {
      if (placeEvent.item.placeId === 'findMe') {
        this.onFindMeClick.emit(true);
      } else {

      }
    } else {
      this.selectPlace.emit(placeEvent.item);
    }
  }

  selectItemMobile(placeEvent: NgbTypeaheadSelectItemEvent<Place>) {
    this.selectPlace.emit(placeEvent.item);
  }
}

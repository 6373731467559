<div class="navbar-container max-width fixed-bottom d-flex safe-area-bottom">
  <nav class="navbar navbar-bottom bg-white flex-fill pr-0">
    <app-navbar-bottom-icon-feed></app-navbar-bottom-icon-feed>

    <div class="flex-content-vc-hc">
      <span
        [ngClass]="{'visibility-hidden': !isLoggedIn}"
        class="icon call"
        (click)="onFavoritesClick()"
      ></span>
    </div>

    <div class="visibility-hidden"></div>
  </nav>

  <app-navbar-bottom-semicircle-notch (menuClick)="onMainMenuClick()">
    <i
      [ngClass]="{'profile-mmmm scale-1_2': rotateMainLogo, 'main-grey': !rotateMainLogo}"
      class="icon"
    ></i>
  </app-navbar-bottom-semicircle-notch>

  <nav class="navbar navbar-bottom bg-white flex-fill pl-0">
    <div class="visibility-hidden"></div>

    <app-navbar-bottom-icon-recent></app-navbar-bottom-icon-recent>

    <app-navbar-bottom-icon-user></app-navbar-bottom-icon-user>
  </nav>
</div>

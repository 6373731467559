import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Auth } from '../../../../decorators/auth.decorator';
import { IsLoggedIn } from '../../../../decorators/is-logged-in.decorator';
import { IsModalOpen } from '../../../../decorators/is-modal-open.decorator';
import { IsSidenavMouseOver } from '../../../../decorators/is-sidenav-mouse-over.decorator';
import { Subscribe } from '../../../../decorators/subscribe.decorator';
import { CityWithChild } from '../../../../domains/city-with-child';
import { CountryWithChild } from '../../../../domains/country-with-child';
import { CountyWithChild } from '../../../../domains/county-with-child';
import { StateWithChild } from '../../../../domains/state-with-child';
import { ModalType } from '../../../../models/enums/modal-type';
import { SearchFiltersPosition } from '../../../../models/enums/search-filters-position';
import { SidenavType } from '../../../../models/enums/sidenav-type';
import { Place } from '../../../../models/place';
import { SearchParams } from '../../../../models/search-params';
import { SearchResult } from '../../../../models/search-result';
import { SearchFiltersPositionWrapper } from '../../../../models/wrappers/search-filters-position.wrapper';
import { FavoritesService } from '../../../../services/favorites.service';
import { NavigatorService } from '../../../../services/navigator.service';

@Component({
  selector: 'app-search-desktop-topnav',
  templateUrl: './search-desktop-topnav.component.html',
  styleUrls: [
    '../../search-desktop/search-desktop.component.scss',
    '../../../../../vendor/libs/ng-select/ng-select.scss',
    '../search-desktop-loader/search-desktop-loader.component.scss'
  ]
})
export class SearchDesktopTopnavComponent implements OnInit {
  @Input() searchParams: SearchParams;
  @Input() searchResult: SearchResult;
  @Input() country: CountryWithChild;
  @Input() preview = false;
  @Input() isSearching: boolean;
  @Input() explorePage = false;
  @Input() isUserPage = false;

  @Output() selectPlaceClicked = new EventEmitter<Place>();
  @Output() findMeClicked = new EventEmitter<boolean>();
  @Output() searchInput = new EventEmitter<string>();
  @Output() videoModalClicked = new EventEmitter<boolean>();
  @Output() citySelected = new EventEmitter<CityWithChild>();
  @Output() previewChange = new EventEmitter<boolean>();
  @Output() locationDropdownChange = new EventEmitter<boolean>();
  @Output() localitySelectedChange = new EventEmitter<boolean>();
  @Output() countyChange = new EventEmitter<CountyWithChild>();
  @Output() stateChange = new EventEmitter<StateWithChild>();

  @IsLoggedIn() isLoggedIn: boolean;

  @IsSidenavMouseOver([SidenavType.LEFT, SidenavType.RIGHT]) isSidenavLeftOrRightMouseOver: boolean;

  @IsModalOpen(ModalType.LOGIN) isLoginModalOpen: boolean;
  @IsModalOpen(ModalType.SUBSCRIBE_DESKTOP) isSubscribeModalOpen: boolean;
  @IsModalOpen(ModalType.RESTAURANT_SQUARE) isRestaurantModalOpen: boolean;
  @IsModalOpen(ModalType.FAVORITE_SQUARE) isFavoriteModalOpen: boolean;

  logoMouseOver = false;
  inputHover = false;
  hiddenPlaceholder = false;
  profileIconHover = false;
  isHovered = false;
  searchFiltersPositionUnderLogo = new SearchFiltersPositionWrapper(SearchFiltersPosition.UNDER_LOGO);

  constructor(
    private navigatorService: NavigatorService,
    private favoritesService: FavoritesService
  ) {
  }

  ngOnInit(): void {
  }

  logoClick() {
    // this.sectionActive = null;
    // this.exploreEmpty.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

    // const offsetRem = 4.5 * parseFloat(getComputedStyle(document.documentElement).fontSize);
    // window.scrollBy({ top: -offsetRem, left: 0, behavior: 'smooth' });
  }

  findMe() {
    this.findMeClicked.emit(true);
  }

  onSelectPlace(place: Place) {
    this.selectPlaceClicked.emit(place);
  }

  searchInputValue(searchValue: string) {
    this.searchInput.emit(searchValue);
  }

  @Auth()
  goToUser() {
    this.navigatorService.goToUser();
  }

  openVideoModal() {
    this.videoModalClicked.emit(true);
  }

  @Auth()
  onFavoriteClick() {
    this.favoritesService.favoriteClickSubject.next(true);
  }

  onCitySelected(citySelected: CityWithChild) {
    this.citySelected.emit(citySelected);
  }

  onPreviewChange() {
    this.previewChange.emit(true);
  }

  onLocationDropdownChange() {
    this.locationDropdownChange.emit(true);
  }

  onLocalitySelectedChange(isLocalitySelected: boolean) {
    this.localitySelectedChange.emit(isLocalitySelected);
  }

  onStateChange(event: StateWithChild) {
    this.stateChange.emit(event);
  }

  onCountyChange(event: CountyWithChild) {
    this.countyChange.emit(event);
  }

  @Auth()
  @Subscribe()
  openMbershipModal() {
  }
}

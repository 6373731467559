<ng-container *ngIf="!isSidenavLeftOrRightMouseOver">
  <nav
    *ngIf="!isLoginModalOpen && !isSubscribeModalOpen && !isRestaurantModalOpen && !isFavoriteModalOpen"
    class="w-100 bg-white z-index-10 position-fixed top-0 d-flex"
  >
    <div class="row py-2 d-flex align-items-center ml-0">
      <div class="search-desktop-main flex-fill">
        <div class="search-desktop-category-section search-desktop-category-section-first">
          <div class="search-desktop-category">
            <div class="search-desktop-restaurant-header d-flex">
              <div class="flex-fill">
                <div class="d-flex mx-0 w-100">
                  <a
                    appHrefNone
                    class="icon-v2 mmmm-red scale-09 mx-3 z-index-10"
                    (mouseover)="logoMouseOver = true"
                    (mouseleave)="logoMouseOver = false"
                    (click)="logoClick()"
                  ></a>

                  <div *ngIf="explorePage" class="form-control-style w-input">
                    <i
                      [ngClass]="{
                        'search-black-new': !hiddenPlaceholder && !inputHover,
                        'search-white': !hiddenPlaceholder && inputHover
                      }"
                      class="icon-v2 ml-3 scale-0-7 position-absolute top-10px h-34px"
                    ></i>

                    <app-search-city-input
                      [searchParams]="searchParams"
                      inputClass="top-input-modal-input search-filters-mobile-location-input"
                      (placeholderHidden)="hiddenPlaceholder = $event"
                      (inputValue)="searchInputValue($event)"
                      (selectPlace)="onSelectPlace($event)"
                      (mouseover)="inputHover = true"
                      (mouseleave)="inputHover = false"
                      (onFindMeClick)="findMe()"
                      class="w-100"
                    ></app-search-city-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="search-desktop-sidebar">
          <div class="search-desktop-controls">
            <div [ngClass]="{'pt-2': explorePage, 'pt-3': isUserPage}">
              <div class="search-desktop-restaurants w-100 d-flex">
                <div class="w-60 d-flex align-items-center">
                  <app-search-filters
                    [country]="country"
                    [searchParams]="searchParams"
                    [isHovered]="isHovered"
                    [preview]="preview"
                    [hideTemporary]="true"
                    [position]="searchFiltersPositionUnderLogo"
                    [isSearching]="isSearching"
                    (citySelectedChange)="onCitySelected($event)"
                    (previewChange)="onPreviewChange()"
                    (locationDropdownChange)="onLocationDropdownChange()"
                    (localitySelectedChange)="onLocalitySelectedChange($event)"
                    (stateSelectedChange)="onStateChange($event)"
                    (countySelectedChange)="onCountyChange($event)"
                    class="ml-1-8rem"
                  ></app-search-filters>

                  <div
                    [ngClass]="{'opacity-0': isSidenavLeftOrRightMouseOver}"
                    class="search-desktop-filters-city ml-0-7rem"
                  >
                    <app-distance-filter
                      *ngIf="searchParams?.geoLocation"
                      [searchParams]="searchParams"
                      [searchResult]="searchResult"
                    ></app-distance-filter>
                  </div>
                </div>

                <div class="w-40 d-flex align-items-center justify-content-end">
                  <a
                    *ngIf="isLoggedIn && explorePage"
                    appHrefNone
                    class="icon call cursor-pointer"
                    (click)="onFavoriteClick()"
                  ></a>

                  <a
                    *ngIf="explorePage"
                    appHrefNone
                    class="icon-v2 play cursor-pointer ml-2rem mb-0-35rem"
                    (click)="openVideoModal()"
                  ></a>

                  <a
                    *ngIf="explorePage"
                    class="icon-v2 profile-dark cursor-pointer ml-2rem"
                    (click)="goToUser()"
                  ></a>

                  <a
                    *ngIf="!explorePage"
                    class="icon chains-circle cursor-pointer ml-2rem"
                    (click)="openMbershipModal()"
                  ></a>

                  <a
                    *ngIf="!explorePage"
                    routerLink="/"
                    class="icon-v2 explore cursor-pointer ml-2rem"
                  ></a>

                  <a
                    class="icon-v2 hamburger-dark cursor-pointer ml-2rem mr-2rem"
                    (mouseover)="profileIconHover = true"
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</ng-container>

<!--<app-layout-left-on-right *ngIf="profileIconHover"></app-layout-left-on-right>-->

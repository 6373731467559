<form autocomplete="off">
  <input
    *appIfIsDesktop
    ngbAutofocus
    [ngbTypeahead]="searchCity"
    [placement]="'bottom-left'"
    [popupClass]="'search-city-popup search-city-popup-desktop'"
    [focusFirst]="false"
    [editable]="false"
    [inputFormatter]="googleMapsService.formatter"
    [resultFormatter]="googleMapsService.formatter"
    [ngClass]="inputClass"
    type="text"
    class="form-control form-control-input input-placeholder"
    id="search-input"
    autocomplete="new-search-city"
    placeholder="        City or Address"
    (selectItem)="selectItem($event)"
    (input)="onInput($event.target)"
    (focus)="onFocus()"
    (mouseenter)="onHover()"
    [resultTemplate]="rt"
  >
  <ng-template #rt let-r="result" let-t="term">
    <div *ngIf="isDesktop" class="d-flex align-items-center"  [class.disabled]="r.disabled">
      <i *ngIf="r.icon" [class]="r.icon"></i>
      <ngb-highlight [result]="r.description" [term]="t"></ngb-highlight>
    </div>
  </ng-template>

  <input
    *appIfIsMobile
    ngbAutofocus
    [ngbTypeahead]="searchCityMobile"
    [placement]="'bottom-left'"
    [popupClass]="'search-city-popup search-city-popup-mobile'"
    [focusFirst]="false"
    [editable]="false"
    [inputFormatter]="googleMapsService.formatter"
    [resultFormatter]="googleMapsService.formatter"
    [ngClass]="inputClass"
    type="text"
    class="form-control text-white"
    placeholder="       City or Address"
    autocomplete="new-search-city"
    (selectItem)="selectItemMobile($event)"
    (input)="onInput($event.target)"
  >
</form>

<nav
  [ngClass]="{'navbar-top-loader': findMeLoader}"
  class="navbar navbar-top navbar-top-px-0 bg-white fixed-top max-width safe-area-top"
>
  <div *ngIf="findMeLoader" class="w-100 h-100">
    <ngx-skeleton-loader
      count="1"
      animation="progress-dark"
      [theme]="{
        'width': '100%',
        'height': '75px',
        'background-color': '#ffffff20'
      }"
    ></ngx-skeleton-loader>
  </div>

  <ng-container *ngIf="!findMeLoader">
    <div class="navbar-top-left">
      <a
        *ngIf="!isLoggedIn"
        appHrefNone
        class="btn btn-primary md-btn-flat pr-0 text-capitalize"
        (click)="openVideoModal()"
      >
        <i class="icon-v2 video-dark-button scale-0-8"></i>
      </a>

      <ng-container *ngIf="!isEditMode && isLoggedIn && userRestaurants.length > 0">
        <a
          *ngIf="userRestaurants.length == 1"
          appHrefNone
          class="btn btn-primary md-btn-flat pr-0 text-capitalize"
          (click)="openMenuEdit(userRestaurants[0])"
        >
          Edit
        </a>

        <app-picker
          *ngIf="userRestaurants.length > 1"
          [picker]="userRestaurantsPicker"
          [title]="'Menu'"
          [titleWidth]="'3.45rem'"
          [classes]="'picker-explore-hours btn btn-primary md-btn-flat pr-0 text-capitalize w-100'"
          (selectedValue)="onMenuPickerSelected($event)"
        ></app-picker>
      </ng-container>
    </div>

    <app-search-filters
      [position]="searchFiltersPositionAsTitle"
      [country]="country"
      [searchParams]="searchParams"
      (searchClicked)="searchClicked()"
    ></app-search-filters>

    <div class="navbar-top-right">
      <ng-container *ngIf="!isEditMode && isLoggedIn && userRestaurants.length > 0">
        <a
          *ngIf="userRestaurants.length == 1"
          appHrefNone
          class="btn btn-primary md-btn-flat pl-0 text-capitalize"
          (click)="openHoursEditModal(userRestaurants[0])"
        >
          Hours
        </a>

        <app-picker
          *ngIf="userRestaurants.length > 1"
          [picker]="userRestaurantsPicker"
          [title]="'Hours'"
          [titleWidth]="'3.45rem'"
          [classes]="'picker-explore-hours btn btn-primary md-btn-flat pl-0 text-capitalize w-100'"
          (selectedValue)="onHoursPickerSelected($event)"
        ></app-picker>
      </ng-container>

      <div *ngIf="!(!isEditMode && isLoggedIn && userRestaurants.length > 0)" class="pr-3">
        <a appHrefNone class="flex-content-vc-hc" (click)="openCitySearchModal()">
          <i class="icon-v2 search-black-new" style="transform: scale(.8)"></i>
        </a>
      </div>
    </div>
  </ng-container>
</nav>

<ng-template #hoursEditModalRef let-c="close">
  <app-hours-edit-modal
    [restaurant]="restaurant"
    [explore]="true"
  ></app-hours-edit-modal>
</ng-template>

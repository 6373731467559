import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import Swiper, { Autoplay, EffectFade, Navigation, Pagination, SwiperOptions } from 'swiper';
import { Auth } from '../../../../decorators/auth.decorator';
import { CurrentUser } from '../../../../decorators/current-user.decorator';
import { ModalClose } from '../../../../decorators/modal-close.decorator';
import { IgUsernameSuggestion } from '../../../../domains/ig-username-suggestion';
import { RestaurantEmbed } from '../../../../domains/restaurant-embed';
import { RestaurantExplore } from '../../../../domains/restaurant-explore';
import { RestaurantSimple } from '../../../../domains/restaurant-simple';
import { User } from '../../../../domains/user';
import { BooleanLocalStorage } from '../../../../local-storage/boolean-local-storage';
import { NumberLocalStorage } from '../../../../local-storage/number-local-storage';
import { StringLocalStorage } from '../../../../local-storage/string-local-storage';
import { EditRole } from '../../../../models/enums/edit-role';
import { LocalStorageKey } from '../../../../models/enums/local-storage-key';
import { ModalType } from '../../../../models/enums/modal-type';
import { TourType } from '../../../../models/enums/tour-type';
import { Modal } from '../../../../models/modal';
import { UnsplashImage } from '../../../../models/unsplash-image';
import { BackgroundImageService } from '../../../../services/background-image.service';
import { EmbedService } from '../../../../services/embed.service';
import { IgUsernameSuggestionService } from '../../../../services/ig-username-suggestion.service';
import { ModalService } from '../../../../services/modal.service';
import { NavigatorService } from '../../../../services/navigator.service';
import { NotificationService } from '../../../../services/notification.service';
import { RestaurantService } from '../../../../services/restaurant.service';
import { ScriptLoaderUtils } from '../../../../utils/script-loader-utils';

Swiper.use([Autoplay, Pagination, Navigation, EffectFade]);

@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['../../../restaurant/menu-router/menu/menu.component.scss', './preview-modal.component.scss']
})
export class PreviewModalComponent implements OnInit {
  @Input() restaurantExplore: RestaurantExplore;
  @Input() title: string;
  @Input() isHeroPageOpen: boolean;
  @Input() isHoursEnabled: boolean;

  @Output() closeClick = new EventEmitter<boolean>();
  @Output() hoursToggle = new EventEmitter<boolean>();

  @ViewChild('videoModalRef') videoModalRef: ElementRef;
  @ViewChild('instagramInputModalRef') instagramInputModalRef: ElementRef;
  @ViewChild('scroller') scrollerRef: ElementRef;

  @CurrentUser() currentUser: User;

  restaurant: RestaurantSimple;
  restaurantEmbeds: Array<RestaurantEmbed> = [];
  igUsernameSuggestion: IgUsernameSuggestion;
  videoModal: Modal;
  instagramInputModal: Modal;
  isEditModeLocalStorage: BooleanLocalStorage;
  editRoleLocalStorage: StringLocalStorage;
  backgroundImage: string | UnsplashImage;
  restaurantPositionLocalStorage = new NumberLocalStorage(LocalStorageKey.RESTAURANT_POSITION);
  videoModalType = TourType;
  topFoodsHasImages = false;
  imagesConfig: SwiperOptions;
  slideIndex = 0;
  restaurantReady = false;

  constructor(
    private logger: NGXLogger,
    private userEmbedService: EmbedService,
    private modalService: ModalService,
    private igUsernameSuggestionService: IgUsernameSuggestionService,
    private navigatorService: NavigatorService,
    private backgroundImageService: BackgroundImageService,
    @Inject(DOCUMENT) private _document: Document,
    private notificationService: NotificationService,
    private restaurantService: RestaurantService
  ) {
  }

  ngOnInit(): void {
    this.imagesConfig = {
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: {
        clickable: true,
        el: '.restaurant-preview-swiper-pagination-' + this.restaurantExplore.id,
        renderBullet: (index, className) => {
          return `<span class="swiper-pagination-bullet"></span>`;
        },
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      noSwiping: true,
      allowTouchMove: false,
      navigation: {
        enabled: true,
        prevEl: '.restaurant-preview-swiper-navigation-left-' + this.restaurantExplore.id,
        nextEl: '.restaurant-preview-swiper-navigation-right-' + this.restaurantExplore.id,
      },
      loop: true,
      effect: 'fade'
    };

    this.loadRestaurant();
  }

  private loadRestaurant() {
    this.restaurantService.getRestaurantSimple(this.restaurantExplore.id).subscribe(restaurant => {
      this.restaurant = restaurant;

      this.restaurantReady = true;

      this.topFoodsHasImages = this.restaurant.slideGalleryFoods?.topFoods.filter(
        topFood => topFood.food.images.length > 0
      ).length > 0;

      const localStorageOptions = {
        hostname: this.restaurant.hostname,
        locationId: this.restaurant.locationIndex,
        userId: this.currentUser?.id
      };

      this.isEditModeLocalStorage = new BooleanLocalStorage(LocalStorageKey.IS_EDIT_MODE, localStorageOptions);
      this.editRoleLocalStorage = new StringLocalStorage(LocalStorageKey.EDIT_ROLE, localStorageOptions);

      this.userEmbedService.getAllRestaurantEmbeds(this.restaurant.id).subscribe(embeds => {
        this.restaurantEmbeds = embeds;
      });

      this.restaurant.periodsGroupAndSort();

      if (this.currentUser) {
        this.igUsernameSuggestionService.getCurrentUserSuggestion(this.restaurant.id).subscribe(value => {
          if (value) {
            this.igUsernameSuggestion = value;
          }
        });
      }

      this.backgroundImageService.getRestaurantBgImageOrRandom(this.restaurant).then(image => {
        this.backgroundImage = image;
      }).catch(e => {
        this.logger.error(e);
      });
    });
  }

  onDeleteUserEmbed(restaurantEmbed: RestaurantEmbed) {
    this.restaurantEmbeds = this.restaurantEmbeds.filter(value => value.id !== restaurantEmbed.id);
  }

  @Auth()
  openVideoModal() {
    this.videoModal = this.modalService.open(this.videoModalRef, ModalType.TOP_ROUNDED);
  }

  @Auth()
  openInstagramInputModal() {
    this.instagramInputModal = this.modalService.open(this.instagramInputModalRef, ModalType.TOP_ROUNDED);
  }

  goToRestaurant() {
    setTimeout(() => {
      const containerSearch = this._document.getElementById('container-search');

      if (containerSearch) {
        this.restaurantPositionLocalStorage.setItem(containerSearch.scrollTop);
      }

      this.navigatorService.goToRestaurantWithObject(this.restaurant);
    }, 50);

    this.modalClose();
  }

  onVideoAdded(userEmbed: RestaurantEmbed) {
    this.closeVideoModal();

    if (userEmbed.html.indexOf('<script') !== -1) {
      userEmbed.isVertical = true;

      ScriptLoaderUtils.loadScriptFromHtml(userEmbed.html, userEmbed.providerName).then(() => {
        this.restaurantEmbeds.unshift(userEmbed);
      });
    } else {
      this.restaurantEmbeds.unshift(userEmbed);
    }

    this.scrollerRef.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  onIgUsernameSuggested(igUsernameSuggestion: IgUsernameSuggestion) {
    this.igUsernameSuggestionService.add(igUsernameSuggestion).subscribe(value => {
      this.igUsernameSuggestion = value;

      this.instagramInputModal.close();
    });
  }

  closeVideoModal() {
    this.videoModal?.close();
  }

  get needToShowBackgroundImage(): boolean {
    return !this.restaurant.published && this.restaurantEmbeds.length === 0;
  }

  @Auth()
  goToRestaurantToEditMode() {
    this.isEditModeLocalStorage.setItem(true);
    this.editRoleLocalStorage.setItem(EditRole.FAN);

    this.goToRestaurant();
  }

  isHeroModalOpen($event) {
    this.isHeroPageOpen = $event;
  }

  @ModalClose()
  modalClose() {
  }

  photoBooth() {
    this.notificationService.photoToastr();
  }

  setIsHoursEnabled($event: boolean) {
    const childElement = document.querySelector(`.restaurant-preview-swiper-pagination-${this.restaurant.id}`);
    const element = childElement.closest('.restaurant-preview-body');

    element.scrollTo({top: 0, behavior: 'smooth'});

    this.hoursToggle.emit($event);
  }

  titleClicked() {
    this.notificationService.titleToastr();
  }

  slideChange($event: [swiper: Swiper]) {
    this.slideIndex = $event[0].realIndex;
  }

  statsClicked() {
    this.notificationService.statsToastr();
  }
}
